

































































































































































































































import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import _ from 'lodash'
import api from '@/api'
import { request, Api, CurriculumResourceFileParams, CurriculumResourceInfo, FileAttachment, IdRecord, PagedResults, ResourceAvailability, ResourceRequiredPermissions, TableState, CurriculumResourceType } from '@/edshed-common/api'
import { locales, LocaleData, getLocaleFromUnderscored } from '@/edshed-common/i18n/locale-map'
import ModalMixin from '@/edshed-common/mixins/ModalMixin'
import ComponentHelper from '@/mixins/ComponentHelper'

@Component({})
export default class ResourcesView extends mixins(ModalMixin, ComponentHelper) {
  private readonly ResourceAvailability = ResourceAvailability
  private readonly ResourceRequiredPermissions = ResourceRequiredPermissions

  private resourceIsUploading: boolean = false
  private resourceUploadProgress: number = 0

  private startCase = _.startCase

  resourceTypes : CurriculumResourceType[] = []

  private rerenderKey: number = 1

  getLocaleFromUnderscored = getLocaleFromUnderscored

  private hasAttemptedToSave = false

  private tableState: TableState = {
    page: 1,
    perPage: 10,
    sort: 'id',
    dir: 'asc',
    term: ''
  }

  private results: PagedResults<CurriculumResourceInfo> = {
    items: [],
    total: 0
  }

  private record: Partial<CurriculumResourceInfo> | null = null

  public mounted () {
    this.$nextTick(() => {
      if (!this.$store.state.user.superuser && !this.$store.state.user.dictionary_editor) {
        this.$router.push('/noaccess')
      } else if (this.$route.hash && window.opener) {
        const id = parseInt(this.$route.hash.substring(1), 10)
        this.loadOne(id)
      } else {
        this.loadTypes()
        this.loadLookups()
        this.loadData()

        if (this.$route.hash) {
          const id = parseInt(this.$route.hash.substring(1), 10)
          this.loadOne(id)
        }
      }
    })
  }

  private async loadTypes () {
    this.resourceTypes = await Api.getResourceTypes()
  }

  private async loadLookups () {}

  private async loadOne (id: number) {
    this.record = await api.getOne(
      'curriculum_resources',
      id
    )
  }

  private async loadData () {
    const { perPage, page, sort, dir, term } = this.tableState

    if (this.tableState.term) {
      const results = await Api.getResources({
        take: perPage,
        skip: perPage * (page - 1),
        term
      })

      this.results = {
        total: results.total,
        items: results.items
      }
    } else {
      this.results = await Api.getResources({
        take: perPage,
        skip: perPage * (page - 1),
        sort,
        dir
      })
    }
  }

  private onPageChange (page: number) {
    this.tableState.page = page

    this.loadData()
  }

  private onSortChange (col: keyof CurriculumResourceInfo) {
    const { sort, dir } = this.tableState

    if (sort !== col) {
      this.tableState.sort = col
      this.tableState.dir = 'asc'
    } else {
      this.tableState.dir = dir === 'asc' ? 'desc' : 'asc'
    }

    this.loadData()
  }

  private onSearchChange () {
    this.loadData()
  }

  private addRow () {
    this.record = {
      title: '',
      description: '',
      required_permissions: [],
      files: [],
      locale: 'en_GB',
      url_key: '',
      type_id: null
    }
  }

  private onEditRow (row: CurriculumResourceInfo) {
    this.record = _.cloneDeep(row)
  }

  private async onDeleteRow (row: CurriculumResourceInfo) {
    try {
      if (await this.deleteConfirm({ name: row.title })) {
        await api.delete(
          'curriculum_resources',
          row.id
        )

        this.loadData()
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        await this.alert({
          title: 'Error',
          message: err.message
        })
      }
    }
  }

  private onCloseEdit () {
    this.record = null
    if (window.opener) {
      window.opener!.postMessage('saved', `${this.config.serverInfo.auth}`)
      window.close()
    }
  }

  private setResourceUploadProgress (percentage: number) {
    this.resourceUploadProgress = percentage
  }

  private async onSaveEdit () {
    const { id } = this.record!

    this.hasAttemptedToSave = true

    if (!this.record?.availability) {
      return
    }

    for (const file of this.record?.files || []) {
      if (!file.downloadable && !file.viewable) {
        return await this.alert({
          title: 'Validation Error',
          message: 'Each file must be either downloadable or viewable'
        })
      }
    }

    try {
      this.setResourceUploadProgress(0)
      this.resourceIsUploading = true

      await api.save(
        'curriculum_resources',
        {
          ...this.record!
        },
        this.setResourceUploadProgress
      )

      this.resourceIsUploading = false

      this.onCloseEdit()
      this.loadData()
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.resourceIsUploading = false
        await this.alert({
          title: 'Error',
          message: err.message
        })
      }
    }
  }

  protected sortLocales<T extends keyof LocaleData> (a: LocaleData, b: LocaleData, field: T, dir: 'asc' | 'desc' = 'asc') {
    return (a[field] > b[field] ? 1 : a[field] < b[field] ? -1 : 0) * (dir === 'asc' ? 1 : -1)
  }

  getCurlyKCheckedState (file: FileAttachment & { params?: CurriculumResourceFileParams }) {
    return file.params?.phonics?.curlyk || false
  }

  onCurlyKInput (file: FileAttachment & { params?: CurriculumResourceFileParams }, checked: boolean) {
    if (!file.params) {
      file.params = {}
    }

    if (!file.params.phonics) {
      file.params.phonics = {}
    }

    file.params.phonics.curlyk = checked
    this.rerenderKey++
  }

  copyResourceURL () {
    if (!this.record || !this.record.ident) { return }
    if (!navigator) { return }
    navigator.clipboard.writeText(`https://shed.ly/${this.record.ident}`)
    this.$buefy.toast.open({
      message: 'URL copied to clipboard',
      type: 'is-success',
      position: 'is-bottom',
      duration: 1500
    })
  }
}
